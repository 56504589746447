import React from 'react'
import './list-item.scss'

/**
 * @typedef ListItemProps
 * @property {string} text
 * @property {string} iconSrc Icon source
 * @property {'small' | 'medium' | 'large'} iconSize
 * @property {'purple' | 'red'} iconColor
 * @property {'link' | 'plain'} textStyle
 * @property {number} index
 */
/**
 * A list item with a circle
 * @param {ListItemProps} props
 * @returns {import('react').ReactElement}
 */
const ListItem = ({ text, iconSrc, iconSize, iconColor, textStyle, index }) => (
   <div className="listItem">
      <div
         className={["listItem--circle listItem--circle__", iconSize].join('')}
         style={{ backgroundColor: iconColor === 'purple' ? '#732682' : '#e64f2a' }}
      >
         {iconSrc ?
         <img
            alt=""
            aria-hidden="true"
            src={iconSrc}
            className={['listItem--image listItem--image__', iconSize].join('')}
         />
         : index}
      </div>

      {textStyle === 'link' ?
      <p
         className="link"
         style={{
            textAlign: 'left',
         }}
      >
         {text}
      </p>
      : <h4>{text}</h4>}
   </div>
)

export default ListItem

ListItem.defaultProps = {
   iconSize: 'medium',
   textStyle: 'link',
   iconColor: 'red',
}

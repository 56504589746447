import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import '../../components/list-item/list-item.scss'
import Sidebar from '../../components/sidebar/sidebar'
import Table from '../../components/table/table'
import { ROUTE_HOME } from '../../constants/routes'
import { useIsMobile } from '../../hooks/useIsMobile'
import Elements from '../../utils/getElements'
import './page-main.scss'

const PageMain = () => {

   const location = useLocation()
   const item = Elements?.find((el) => el.routeName === location.pathname)
   const isMobile = useIsMobile({ width: 768 })
   const navigate = useNavigate()

   const [selectedObjective, setSelectedObjective] = useState(null)

   useEffect(() => {
      if (!item) return navigate(ROUTE_HOME) // Navigate to home from unsupported urls
      if (!selectedObjective) setSelectedObjective(item.objectives[0])
   }, [selectedObjective, item, location.pathname, navigate])

   useEffect(() => {
      if (item) setSelectedObjective(item.objectives[0])
   }, [location.pathname, item])

   if (!item || !selectedObjective) return null // Redirect

   const mobileView = (
      <div>
         {item.objectives.map((objective, index) => (
            <div key={objective.title} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
               <h4 style={{ marginBottom: 10, marginTop: 20 }}>Tavoite</h4>
               <div
                  className="listItem--circle listItem--circle__medium"
                  style={{ backgroundColor: '#e64f2a', marginRight: 0, marginBottom: 10 }}
               >
                  {index + 1}
               </div>
               <h2 style={{ padding: '0 20px', marginBottom: 46, textAlign: 'center' }}>{objective.title}</h2>
               {objective.measures.map(({ title, tasks }, index) => (
                  <div className="tableWrapper" key={title}>
                     <h4 style={{ padding: '0 20px', marginBottom: 4, marginTop: 24 }}>Toimenpide</h4>
                     <h3 style={{ textAlign: 'left', marginBottom: 16, maxWidth: 'calc(100% - 40px)', padding: '0 20px' }}>{title}</h3>
                     <Table items={tasks} isFirst={index === 0} />
                  </div>
               ))}
            </div>
         ))}
      </div>
   )

   return (
      <div className="page">

         <Sidebar
            title={item.title}
            showMainItems
            items={item.objectives}
            powerBiLink={item.powerBiLink}
            onSelect={(item) => setSelectedObjective(item)}
            activeItemIndex={item.objectives.indexOf(selectedObjective)}
         />

         {isMobile
         ? mobileView
         : selectedObjective &&
         <div className="page--content pageMain">
            {selectedObjective?.title &&
            <h2
               style={{
                  textAlign: 'center',
                  marginBottom: 48,
                  width: '75%',
               }}
            >
               {selectedObjective.title}
            </h2>}
            <h4 style={{ textAlign: 'center', marginBottom: 8 }}>Toimenpide</h4>
            {selectedObjective.measures.map(({ title, tasks }, index) => (
               <div className="pageMain--tableWrapper" key={title}>
                  <h3 style={{ textAlign: 'center', marginBottom: 26, width: '75%', maxWidth: 600 }}>{title}</h3>
                  <Table items={tasks} isFirst={index === 0} />
               </div>
            ))}
         </div>}

      </div>
   )
}

export default PageMain

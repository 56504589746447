import { useDebugValue, useEffect, useState } from "react";

export function useIsMobile({
  width = 768,
  customAgent = navigator.userAgent
} = {}) {
  const [isMobileState, setIsMobile] = useState(
    window.innerWidth <= width
  );

  useEffect(() => {
    function handleResize() {
      if (
        global.window.innerWidth <= width
      ) {
        if (!isMobileState) {
          setIsMobile(true);
        }
      } else if (isMobileState) {
        setIsMobile(false);
      }
    }

    global.window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      global.window.removeEventListener("resize", handleResize);
    };
  });

  useDebugValue("useIsMobile");

  return isMobileState;
}

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.scss'
import { ROUTE_HOME } from './constants/routes'
import PageStart from './pages/page-start/page-start'
import PageMain from './pages/page-main/page-main'

function App() {

   return (
      <Router>
         <Routes>
            <Route path={ROUTE_HOME} element={<PageStart />} />
            <Route path={'/:element'} element={<PageMain />} />
            <Route path="*" element={<PageStart />} />
         </Routes>
      </Router>
   )
}

export default App

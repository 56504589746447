function getRouteName(title) {
   const t = title
      .replaceAll(' ', '-')
      .replaceAll('ä', 'a')
      .replaceAll('ö', 'o')
      .toLowerCase()
   return `/${t}`
}

export default getRouteName

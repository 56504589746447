import React from 'react'
import ImageMobile from '../../assets/images/etusivu-piirroskuva-03.svg'
import LogoKuopio from '../../assets/images/kuopio_logo.png'
import ListItem from '../../components/list-item/list-item'
import Sidebar, { SidebarButton } from '../../components/sidebar/sidebar'
import { useIsMobile } from '../../hooks/useIsMobile'
import Elements from '../../utils/getElements'
import './page-start.scss'

const PageHome = () => {

   const isMobile = useIsMobile({ width: 768 })

   const mobileView = (

      <div className="page">

         <div className="pageStart--content">
            <img aria-hidden="true" alt="" src={LogoKuopio} className="kuopioLogo" />
            <h1 style={{ marginBottom: 24 }}>Hyvinvointi&shy;suunnitelma 2021-2025</h1>
            <h2 style={{ marginBottom: 34 }}>Matkalla hyvän elämän pääkaupungiksi</h2>
            <div>
               {list}
            </div>

            <h4 className="pageStart--description">
               Hyvinvointisuunnitelma ohjaa toimintaa kuopiolaisten hyvinvoinnin ja terveyden edistämiseksi. Keskiössä on hyvinvoinnin ja terveyden tasa-arvo, terveyden, mielenhyvinvoinnin ja työkyvyn tukeminen sekä normaalipainoisuuden edistäminen.
               <br />
               <br />
               Suunnitelman pohjana on viisi hyvinvoinnin osa-aluetta, hyvän elämän elementtiä. Hyvinvointisuunnitelmassa on esitetty yhteensä 27 toimenpidettä ja 100 tehtävää, joilla tuetaan tavoitteita hyvän elämän elementtien vahvistamiseksi. Tämän sivuston kautta voit tutustua näihin tavoitteisiin, toimenpiteisiin ja tehtäviin tarkemmin. Lisäksi saat tietoa tavoitteiden etenemisestä.
               <br />
               <br />
               Kuopion hyvinvointisuunnitelma sisältää myös lasten ja nuorten hyvinvointisuunnitelman, ehkäisevän päihdetyön suunnitelman sekä näkökulmia arjen turvallisuuden edistämisestä.
               <br />
               <br />
               Tutustumalla koko suunnitelmaan saat lisätietoa suunnitelman laadinnasta, toteutuksesta ja arvioinnista. Lisäksi löydät tietoa suunnitelmaa ohjaavista suosituksista ja ohjelmista.
            </h4>

         </div>

         <div className="mobileElements">
            {Elements.map((item, index) => (
               <SidebarButton
                  key={item.title}
                  item={item}
                  number={index + 1}
                  circleSize={'large'}
               />
            ))}
         </div>

         <img
            alt=""
            aria-hidden="true"
            src={ImageMobile}
            style={{
               padding: 40,
               paddingBottom: 12,
               maxHeight: 700,
            }}
         />

         <a
            href={`${process.env.PUBLIC_URL}/Hyvinvointisuunnitelma 2021-2025 paivitetty 2023.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            className="frontPageLink"
            style={{
               textAlign: 'center',
               padding: 40,
               paddingBottom: 20,
            }}
         >
            Katso koko hyvinvointisuunnisuunnitelma
         </a>
         <a
            href="https://app.powerbi.com/view?r=eyJrIjoiYmQ5NzRlYWUtNDc3Ny00Y2EzLWI1YmQtYzkxMWNjYjY0NWI0IiwidCI6ImVjNmMyMzdlLTg4ZWEtNGNjMy05NGVmLTU5NWIyN2E4ZWJmOSIsImMiOjl9"
            target="_blank"
            rel="noopener noreferrer"
            className="frontPageLink"
            style={{
               textAlign: 'center',
               padding: 40,
               paddingTop: 20,
            }}
         >
            Tietoa hyvinvointityöstä
         </a>

      </div>

   )

   if (isMobile) return mobileView

   return (

      <div className="page">

         <Sidebar
            items={Elements}
            showMainItems={false}
         />

         <div className="pageStart--content">
            <div className="pageStart--content--inner">
               <img aria-hidden="true" alt="" src={LogoKuopio} className="kuopioLogo" />
               <h1 style={{ marginBottom: 24, marginTop: 28, paddingRight: 120 }}>Hyvinvointi&shy;suunnitelma<br />2021-2025</h1>
               <h2 style={{ marginBottom: 44 }}>Matkalla hyvän elämän pääkaupungiksi</h2>
               <div>
                  {list}
               </div>

               <h4 className='pageStart--description'>
                  Hyvinvointisuunnitelma ohjaa toimintaa kuopiolaisten hyvinvoinnin ja terveyden edistämiseksi. Keskiössä on hyvinvoinnin ja terveyden tasa-arvo, terveyden, mielenhyvinvoinnin ja työkyvyn tukeminen sekä normaalipainoisuuden edistäminen.
                  <br />
                  <br />
                  Suunnitelman pohjana on viisi hyvinvoinnin osa-aluetta, hyvän elämän elementtiä. Hyvinvointisuunnitelmassa on esitetty yhteensä 27 toimenpidettä ja 100 tehtävää, joilla tuetaan tavoitteita hyvän elämän elementtien vahvistamiseksi. Tämän sivuston kautta voit tutustua näihin tavoitteisiin, toimenpiteisiin ja tehtäviin tarkemmin. Lisäksi saat tietoa tavoitteiden etenemisestä.
                  <br />
                  <br />
                  Kuopion hyvinvointisuunnitelma sisältää myös lasten ja nuorten hyvinvointisuunnitelman, ehkäisevän päihdetyön suunnitelman sekä näkökulmia arjen turvallisuuden edistämisestä.
                  <br />
                  <br />
                  Tutustumalla koko suunnitelmaan saat lisätietoa suunnitelman laadinnasta, toteutuksesta ja arvioinnista. Lisäksi löydät tietoa suunnitelmaa ohjaavista suosituksista ja ohjelmista.
               </h4>

            </div>

         </div>

      </div>
   )
}

export default PageHome

const listItemProps = {
   textStyle: 'plain',
   iconSize: 'small',
   iconColor: 'purple',
}

const list = (
   <>
      <ListItem {...listItemProps} index={5} text="Hyvän elämän elementtiä" />
      <ListItem {...listItemProps} index={13} text="Tavoitetta" />
      <ListItem {...listItemProps} index={27} text="Toimenpidettä" />
      <ListItem {...listItemProps} index={100} text="Tehtävää" />
   </>
)

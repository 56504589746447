import elementRoutines from '../elements/hyvan-arjen-rutiinit.json'
import elementFellowTravelers from '../elements/hyvan-elaman-kanssakulkijat.json'
import elementInspirers from '../elements/hyvan-elaman-innostajat.json'
import elementEnvironment from '../elements/hyvan-elaman-asuinymparisto.json'
import elementIncome from '../elements/hyvan-elaman-toimeentulo.json'
import Element from '../models/Element'
import IconRoutines from '../assets/icons/hyvan-arjen-rutiinit.svg'
import IconFellowTravelers from '../assets/icons/hyvan-elaman-kanssakulkijat.svg'
import IconInspirers from '../assets/icons/hyvan-elaman-innostajat.svg'
import IconIncome from '../assets/icons/hyvan-elaman-toimeentulo.svg'
import IconEnvironment from '../assets/icons/hyvan-elaman-asuinymparisto.svg'

const Elements = [
   Element.fromJson({ ...elementRoutines, icon: IconRoutines }),
   Element.fromJson({ ...elementFellowTravelers, icon: IconFellowTravelers }),
   Element.fromJson({ ...elementInspirers, icon: IconInspirers }),
   Element.fromJson({ ...elementIncome, icon: IconIncome }),
   Element.fromJson({ ...elementEnvironment, icon: IconEnvironment }),
]

export default Elements

import getRouteName from "../utils/getRouteName"

export default class Element {

   /**
    * @typedef Task // Tehtävä
    * @property {string} title
    * @property {string} evaluation
    */
   /**
    * @typedef Measure // Toimenpide
    * @property {string} title
    * @property {Task[]} tasks
    */
   /**
    * @typedef Objective // Tavoite
    * @property {string} title
    * @property {Measure[]} measures
    */
   /**
    * @param {string} title
    * @param {string} powerBiLink
    * @param {*} icon
    * @param {Objective[]} objectives
    */
   constructor(title, routeName, powerBiLink, icon, objectives) { // Hyvän elämän elementti
      this.title = title
      this.routeName = routeName
      this.powerBiLink = powerBiLink
      this.icon = icon
      this.objectives = objectives
   }

   /**
    * @param {Partial<Element>} json
    * @returns {Element}
    */
   static fromJson(json) {
      const { title, powerBiLink, icon, objectives } = json
      return new Element(
         title,
         getRouteName(title),
         powerBiLink,
         icon,
         objectives.map(({ title, measures }) => ({
            title,
            measures: measures.map(({ title, tasks }) => ({
               title,
               tasks: tasks.map(({ title, evaluation }) => ({
                  title,
                  evaluation,
               })
            )}))
         }))
      )
   }

}

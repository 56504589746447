import React from 'react'
import { useIsMobile } from '../../hooks/useIsMobile'
import './table.scss'

/**
 * @typedef TableItem
 * @property {string} title // "Tehtävä"
 * @property {string} evaluation // "Toteutuksen arviointi"
 */
/**
 * @typedef TableProps
 * @property {TableItem[]} items
 * @property {boolean} isFirst
 */
/**
 * @param {TableProps} props
 * @returns {import('react').ReactElement}
 */
const Table = ({ items, isFirst }) => {

   const isMobile = useIsMobile({ width: 768 })

   const MobileTable = (
      <div className="mobileTable">

         {isFirst && <h4 className="mobileTable--title">Tehtävä ja toteutuksen arviointi</h4>}

         {items.map(({ title, evaluation }, index) => (
            <div className="mobileTable--taskAndEvaluation" style={{ marginBottom: index + 1 === items.length && 0 }} key={title}>
               <div className="mobileTable--taskAndEvaluation--content">
                  <p className="td--number" style={{ paddingRight: 0 }}>{index + 1}.</p>
                  <p dangerouslySetInnerHTML={{ __html: title }} />
               </div>
               <p style={{ color: '#732682', fontStyle: 'italic', paddingLeft: 10 }}>{evaluation}</p>
            </div>
         ))}
      </div>
   )

   const DesktopTable = (
      <div className="desktopTable">
         {isFirst &&
         <div className="desktopTable--headers">
            <h4 className="desktopTable--headers--header" style={{ width: '64%' }}>Tehtävät</h4>
            <h4 className="desktopTable--headers--header">Toteutuksen arviointi</h4>
         </div>}
         <table>
            <tbody>
               {items.map(({ title, evaluation }, index) => (
                  <tr key={title}>
                     <td>
                        <p className="td--number">{index + 1}.</p>
                        <p dangerouslySetInnerHTML={{ __html: removePTag(title) }}></p>
                     </td>
                     <td className="td--evaluation">
                        <p>{evaluation}</p>
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   )

   if (isMobile) return MobileTable
   return DesktopTable

}

export default Table

const removePTag = (text) => (
   text.replaceAll('<p>', '').replaceAll('</p>', '')
)

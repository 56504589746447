import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import IconArrow from '../../assets/icons/nuoli-04.svg'
import ArrowIcon from '../../assets/icons/nuoli-oranssi.svg'
import { ROUTE_HOME } from '../../constants/routes'
import { useIsMobile } from '../../hooks/useIsMobile'
import Elements from '../../utils/getElements'
import ListItem from '../list-item/list-item'
import './sidebar.scss'

/**
 * @typedef SidebarItem
 * @property {string} icon
 * @property {string} title
 * @property {string} routeName
 */
/**
 * @typedef SidebarProps
 * @property {string} title
 * @property {SidebarItem[]} items
 * @property {string} powerBiLink
 * @property {boolean} showMainItems
 * @property {Function} onSelect
 * @property {number} activeItemIndex
 */
/**
 * @param {SidebarProps} props
 * @returns {import('react').ReactElement}
 */
const Sidebar = ({ title, items, powerBiLink, showMainItems, onSelect, activeItemIndex }) => {

   const isMobile = useIsMobile({ width: 768 })

   // Tooltips
   const elementsRefs = useRef([]) // Includes all Element buttons
   const [tooltip, setTooltip] = useState(false) // Tooltip text
   const [hoverEl, setHoverEl] = useState(null) // Index of the hovered element button
   const [tooltipPosition, setTooltipPosition] = useState([0, 0]) // [x, y]

   // Adjust tooltip position
   useEffect(() => {
      if (![null, undefined].includes(hoverEl)) {
         const el = elementsRefs.current[hoverEl]
         if (el) {
            const rect = el.getBoundingClientRect()
            setTooltipPosition([rect.x, rect.y])
         }
      }
   }, [hoverEl])

   return (
      <>
         {tooltip &&
         <div
            className='tooltip'
            style={{ marginLeft: tooltipPosition[0], marginTop: tooltipPosition[1] + 48 }}
         >
            <p>{tooltip}</p>
         </div>}

         <div className="sidebar">

         <div className={isMobile ? "sidebar" : null}>

         {showMainItems &&
         <div className="sidebar--upperSection">

            <Link
               to={ROUTE_HOME}
               className="sidebar--backButton"
               title="Palaa etusivulle"
            >
               <img
                  alt=""
                  aria-hidden="true"
                  src={IconArrow}
                  className="sidebar--backButton--image"
               />
            </Link>

            {title && <h3 style={{ marginTop: 12 }}>{title}</h3>}

            <div className="sidebar--elements">
               {Elements.map((item, index) => (
                  <div
                     style={{ position: 'relative' }}
                     key={item.title}
                     ref={(el) => elementsRefs.current.push(el)}
                     id={item.routeName.substr(1, item.routeName.length - 1)}
                  >
                     <NavLink
                        aria-label={item.title}
                        key={item.routeName}
                        to={item.routeName}
                        className={"listItem--circle listItem--circle__medium"} // list-item.scss
                        style={({ isActive }) => ({
                           opacity: isActive ? 1 : 0.5,
                           margin: 0,
                        })}
                        state={{ item }}
                        onMouseEnter={() => {
                           setTooltip(item.title)
                           setHoverEl(index)
                        }}
                        onMouseLeave={() => {
                           setTooltip(false)
                           setHoverEl(null)
                        }}
                     >
                        <img
                           aria-hidden="true"
                           alt=""
                           src={item.icon}
                           style={{ width: 42, aspectRatio: 1 }}
                        />
                     </NavLink>
                  </div>
               ))}
            </div>
         </div>}

         {title &&
         <h4 className="sidebar--titleObjectives" style={{ margin: '40px 36px 12px' }}>
            Tavoitteet
         </h4>}

         {!isMobile && items?.map((item, index) => (
            <SidebarButton
               key={item.title}
               item={item}
               number={index + 1}
               circleSize={showMainItems ? 'medium' : 'large'}
               onSelect={() => onSelect(item)}
               isActive={activeItemIndex === index}
            />
         ))}

         {powerBiLink &&
         <a
            href={powerBiLink}
            className="sidebar--additionalLink"
            rel="noopener noreferrer"
            target="_blank"
         >
            <div
               style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
               }}
            >
               <p className="link">Seuraa toiminnan toteutumista ja vaikutuksia</p>
               <img
                  alt=""
                  aria-hidden="true"
                  src={ArrowIcon}
                  style={{
                     marginLeft: 12,
                     transform: 'rotate(180deg)',
                     width: 8,
                     aspectRatio: 1,
                  }}
               />
            </div>
         </a>}

         </div>

         {!powerBiLink &&
         <div
            className='sidebar--bottomLinks'
         >
            <p className='bottomLink' style={{ marginBottom: 24 }}>
               <a
                  href={`${process.env.PUBLIC_URL}/Hyvinvointisuunnitelma 2021-2025 paivitetty 2023.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  Katso koko hyvinvointisuunnitelma
               </a>
            </p>

            <p className='bottomLink'>
               <a
                  href="https://app.powerbi.com/view?r=eyJrIjoiYmQ5NzRlYWUtNDc3Ny00Y2EzLWI1YmQtYzkxMWNjYjY0NWI0IiwidCI6ImVjNmMyMzdlLTg4ZWEtNGNjMy05NGVmLTU5NWIyN2E4ZWJmOSIsImMiOjl9"
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  Tietoa hyvinvointityöstä
               </a>
            </p>
         </div>}

      </div>
      </>
   )
}

export default Sidebar

/**
 * @typedef SidebarItemProps
 * @property {SidebarItem} item
 * @property {number} number Number displayed in the circle
 * @property {'medium' | 'large'} circleSize
 * @property {Function} onSelect If the item isn't a "main menu route", this is executed on click
 */
/**
 * @param {SidebarItemProps} props
 * @returns {import('react').ReactElement}
 */
export const SidebarButton = ({ item, number, circleSize, onSelect, isActive }) => {

   const { title, icon, routeName } = item

   if (!routeName) return (
      <button
         className={["sidebar--button", isActive ? 'sidebar--button__active' : null].join(' ')}
         onClick={onSelect}
      >
         <ListItem
            iconSrc={icon}
            iconSize="medium"
            iconColor="red"
            text={title}
            textStyle="link"
            index={number}
         />
      </button>
   )

   return (
      <NavLink
         to={routeName}
         className={["sidebar--button", isActive ? 'sidebar--button__active' : null].join(' ')}
         state={item}
      >
         <ListItem
            iconSrc={icon}
            index={number}
            text={title}
            iconColor="red"
            iconSize={circleSize}
            textStyle="link"
         />
      </NavLink>
   )
}
